import Vue from 'vue'
import App from './App.vue'
import router from './router/router.js'
import store from './store/store.js'
import './plugins/apexchart'
import './style/app.scss'
import './style/variables.scss'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import lang from 'element-ui/lib/locale/lang/es';
import locale from 'element-ui/lib/locale';

locale.use(lang);

Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  beforeCreate() { this.$store.dispatch('INITIALIZE_STORE'); },
  render: h => h(App),
}).$mount('#app')
