import common from "./common";

export const adminManager = () => {
  const formatReviewChanges = (input) => {
    const changes = [];
    // Process supplier_changes_individual
    if (input) {
      for (const change of input.supplier_changes_individual) {
        const formattedChange = {
          id: change.id,
          type: "supplier_changes",
          old_supplier: change.old_supplier_name,
          new_supplier: change.new_supplier_name,
          export_id: change.type === "EXPORT" ? change.id_service : null,
          import_id: change.type === "IMPORT" ? change.id_service : null,
          reason: change.motive,
          comments: change.comment,
          user: change.user,
          datetime: common.formatUtcDateToLocal(change.created_at),
          orderData: {},
        };
        changes.push(formattedChange);
      }

      // Process break_combination_changes
      for (const change of input.break_combination_changes) {
        const formattedChange = {
          id: change.id,
          type: "break_combination_changes",
          old_supplier: null,
          new_supplier: null,
          export_id: change.export_id,
          import_id: change.import_id,
          reason: change.motive,
          comments: change.comment,
          user: change.user,
          datetime: common.formatUtcDateToLocal(change.created_at),
          orderData: {},
        };
        changes.push(formattedChange);
      }

      // Process supplier_changes_combination
      for (const change of input.supplier_changes_combination) {
        const formattedChange = {
          import_change_id: change.import_change_id,
          export_change_id: change.export_change_id,
          type: "supplier_changes",
          old_supplier: change.old_supplier_name,
          new_supplier: change.new_supplier_name,
          is_supplier_new: change.id_old_supplier,
          export_id: change.export_id,
          import_id: change.import_id,
          reason: change.motive,
          comments: change.comment,
          user: change.user,
          datetime: common.formatUtcDateToLocal(change.created_at),
          orderData: {},
        };
        changes.push(formattedChange);
      }

      //Process change_routes
      for (const change of input.change_routes) {
        const formattedChange = {
          id: change.id,
          type: "change_routes",
          old_supplier: change.old_supplier_name,
          new_supplier: change.supplier_name,
          old_import: change.old_import,
          id_port: change.id_port,
          export_id: change.export_id,
          import_id: change.import_id,
          reason: change.motive,
          comments: change.comment,
          user: change.user,
          datetime: common.formatUtcDateToLocal(change.created_at),
          orderData: {},
        };
        changes.push(formattedChange);
      }
    }
    return changes;
  };

  return {
    formatReviewChanges,
  };
};

export default adminManager();
