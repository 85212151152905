export const sessionStorageInterface = () => {
  const isAuthenticated = () => {
    const auth = sessionStorage.getItem('authenticated')
    return auth === 'true'
  }

  const signIn = (token, user_id, role_id) => {
    sessionStorage.setItem('authenticated', 'true')
    sessionStorage.setItem('token', token)
    sessionStorage.setItem('user_id', user_id)
    sessionStorage.setItem('role', role_id)
    console.log('Sign in ok')
    return true
  }

  const signOut = () => {
    sessionStorage.removeItem('authenticated')
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('user_id')
    sessionStorage.removeItem('role')
    return true
  }

  const getToken = () => sessionStorage.getItem('token')

  const getUserId = () => sessionStorage.getItem('user_id')

  const getRole = () => sessionStorage.getItem('role')

  const loadedData = () => sessionStorage.setItem('last_update', new Date().toISOString())

  return {
    getToken,
    getUserId,
    getRole,
    isAuthenticated,
    loadedData,
    signIn,
    signOut,
  }
}

export default sessionStorageInterface()
