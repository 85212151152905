import Vue from "vue";
import VueRouter from "vue-router";
import session from "@/methods/session_storage";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/MainView"),
    beforeEnter: (to, from, next) => {
      if (!session.isAuthenticated() && to.name !== "Sign In") {
        next("/sign-in");
      }
      next();
    },
  },
  {
    name: "Sign In",
    path: "/sign-in",
    component: () => import("@/views/SignInView"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_BASE_URL,
  routes,
});

export default router;
