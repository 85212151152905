import moment from 'moment-timezone';

export const commonMethods = () => {

  const isValidDate = (date) => {
      return date !== "Invalid Date" && date !== null;
  }

  const formatDate = (date) => {
    if (isValidDate(date)) {
      var dateParts = date.split(/[/, ]/);
      var hourMinutesParts = dateParts[4].split(":");
      var day = parseInt(dateParts[0], 10);
      var month = parseInt(dateParts[1], 10) - 1; // Month is zero-based in JavaScript
      var year = parseInt(dateParts[2], 10);
      var hour = parseInt(hourMinutesParts[0], 10);
      var minutes = parseInt(hourMinutesParts[1], 10);

      return new Date(year, month, day, hour, minutes);
      }
  }

  const formatBoolean = (boolean) => {
      return boolean ? "Sí" : "No";
  }

  const formatUtcDateToLocal = (date) => {
      const utcDate = moment.utc(date);
      const timeZone = moment.tz.guess();
      const localDate = utcDate.tz(timeZone);
      const localDateTimeString = localDate.format('YYYY-MM-DD HH:mm');

      return localDateTimeString
  }

  return {
    isValidDate,
    formatDate,
    formatBoolean,
    formatUtcDateToLocal
  }
}

export default commonMethods()